var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(
        "mega-header",
        {
          staticClass: "overlay-dark overlay-90 text-lighter",
          attrs: {
            title: `ARROUND ${_vm.$t("moderation")}`,
            background:
              "https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("welcome")) + " " + _vm._s(_vm.user.email) + "!"
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c(
            "div",
            { staticClass: "container py-3 sm-down:flex-column" },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-primary md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3",
                  attrs: { to: { name: "Support" } }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("support")))])]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }