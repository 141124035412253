<template>
  <div class="dashboard-page">
    <mega-header
      class="overlay-dark overlay-90 text-lighter"
      :title="`ARROUND ${$t('moderation')}`"
      background="https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    >
      <span class="text-muted">{{ $t("welcome") }} {{ user.email }}!</span>
    </mega-header>

    <div
      class="navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3 sm-down:flex-column">
        <router-link
          :to="{ name: 'Support' }"
          class="btn btn-sm btn-primary md-up:mr-3 text-left d-block sm-down:w-100 sm-down:mb-3"
        >
          <span>{{ $t("support") }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  mounted() {
    this.$navbar.name = this.$t("moderation");
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>
